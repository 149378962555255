<template>
<!-- 상태 변경 팝업 -->
<transition name="modal" appear>
    <div class="modal modal-overlay" v-if="onModal" @click.self="handleWrapperClick">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" v-if="style='width:300px;'">
                <div class="modal-body">
                    <a class="btn_layer mr-10 pointer" @click="detail()">차트</a>
                    <a v-if="reservationStatus !== '입실' && isModify" class="btn_layer mr-10 pointer" @click="putReservationStatus()">입실</a>
                    <a v-if="reservationStatus !== '입실' && isModify" class="btn_layer mr-10 pointer" @click="modify()">변경</a>
                    <a class="btn_layer mr-10 pointer" @click="$emit('update:onModal', !onModal)">닫기 </a>
                </div>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
export default {

    props: {
        onModal: {
            type: Boolean,
            require: true,
            default: false
        },

        idxCrmReservation: {
            type: Number,
            require: false
        },

        reservationStatus: {
            type: String,
            require: false
        },

        clientType: {
            type:String,
            require: false
        },

        idxCrmClient: {
            type: Number,
            require: false
        },

        idxCrmCompany: {
            type:Number,
            require: false
        },

        isModify:{
            type: Boolean,
            require: false
        }

    },

    data: () => ({
        status: '입실'
    }),

    mounted() {
    },

    methods: {

        handleWrapperClick() {
            this.$emit('update:onModal', false)
        },

        modify() {
            this.$emit('modifyPreview')
        },

        // 수납등록 페이지로 이동
        purchase () {
            
            let idxCrmClient = this.idxCrmClient
            let idxCrmCompany = this.idxCrmCompany

            if(this.clientType === 'B2C') {
                this.$router.push(`/origin/customer_acceptance_register_b2c?idxCrmClient=${idxCrmClient}`)
            } else {
                this.$router.push(`/origin/customer_acceptance_register_b2b?idxCrmCompany=${idxCrmCompany}`)
            }

        },

        // 상담예약관리 상세정보 페이지로 이동
        detail() {
            let idx = this.idxCrmClient
            this.$router.push(`/counselor/counselor_customer_manage_chart?idx=${idx}`)
        },

        putReservationStatus() {

            let idx = this.idxCrmReservation

            let params = new URLSearchParams();
            params.append('status', this.status)

            this.axios.put(`/api/v1/cons/status/${idx}`, params )
                .then(res => {
                    if (res.data.err === 0) {
                        alert('입실 처리 되었습니다.');
                        this.closeModal();
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.error(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 예약 삭제
        remove() {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {

                this.axios.delete(`/api/v1/cons/${this.idxCrmReservation}`)
                    .then(res => {
                        if (res.data.err === 0) {
                            alert('삭제되었습니다.');
                            this.closeModal();
                        } else {
                            alert(res.data.result);
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        alert(err)
                    })
            } else {
                return false
            }

        },

        /**
         * @description : 모달 닫기
         */
        closeModal(){
            this.$emit('modalCloseFromPreview');
        }
    }
}
</script>

<style lang="scss" scoped>
.modal {
    &.modal-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 30;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }

    &-window {
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
    }

    &-content {
        padding: 20px 10px 20px 20px;
        background: #fff;
    }

    &-footer {
        // background: #ccc;
        padding: 10px;
        text-align: right;
    }
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.4s;

    .modal-window {
        transition: opacity 0.4s, transform 0.4s;
    }
}

.modal-leave-active {
    transition: opacity 0.4s ease 0.2s;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;

    .modal-window {
        opacity: 0;
        transform: translateY(-20px);
    }
}
</style>
