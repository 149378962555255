<template>
  <div>
    <div class="wrap">
      <form name="mfrm" action="/menu_management_status/index" method="post">
        <div class="con_top">
          <div class="borad-cast">
            <div class="event-board mt-05">공지사항</div>
            <div class="event-board">
              <div class="slider" v-if="boardList.length > 0">
                <VueSlickCarousel v-bind="slideOptions">
                  <div v-for="(item, index) of boardList" :key="index">
                    <button v-show="item.new" class="new-button" type="button" @click="moveToNoticeDetail(item.idx)">NEW</button>
                    <span class="notice-title" @click="moveToNoticeDetail(item.idx)">{{ item.title }}</span>
                  </div>
                </VueSlickCarousel>
              </div>
            </div>
            <div class="event-board mt-05">
              <div class="pointer" @click="moveToNotice()">MORE</div>
            </div>
          </div>
          <div class="boardLine"></div>
          <h2><span>■</span> 상담예약현황</h2>
        </div>

        <div class="con">
          <div class="con_table">
            <ul class="month">
              <li>
                <a class="pointer" @click="prevDay()"><img src="@/assets/images/pre_active.png"/></a>
              </li>
              <li>
                <p>{{ todayYear }}.{{ todayMonth }}</p>
              </li>
              <li>
                <a class="pointer" @click="nextDay()"><img src="@/assets/images/next_active.png"/></a>
              </li>
              <li>
                <form name="frm" action="/menu_schedule/index" method="get">
                  <input type="hidden" name="goto" id="goto" value="next" />
                  <input type="text" name="date" id="date" value="2020-10-19" style="width:0px;border: 0;" readonly="readonly" onchange="goToDay(this.value)" class="hasDatepicker" />
                </form>
              </li>
            </ul>
            <div class="con">
              <table class="counselor-table mt-20">
                <tbody v-if="loading">
                  <tr>
                    <th>일</th>
                    <th>월</th>
                    <th>화</th>
                    <th>수</th>
                    <th>목</th>
                    <th>금</th>
                    <th>토</th>
                  </tr>
                  <tr>
                    <td colspan="8">검색중입니다.</td>
                  </tr>
                </tbody>
                <tbody v-else style="table-layout: fixed">
                  <tr>
                    <th width="13%">일</th>
                    <th width="13%">월</th>
                    <th width="13%">화</th>
                    <th width="13%">수</th>
                    <th width="13%">목</th>
                    <th width="13%">금</th>
                    <th width="13%">토</th>
                  </tr>
                  <tr v-for="(item, index) of calendarTable" :key="index">
                    <td v-for="(subItem, idx) of item" :key="idx">
                      <div class="flex">
                        <span v-if="subItem.day" class="client-info underline font_color pointer bold" @click="preview(resData, subItem.today)">
                          ▶ 예약등록
                        </span>
                        <span class="day">{{ subItem.day }}</span>
                        <div v-for="(resData, subidx) of subItem.resData" :key="subidx" class="res-info">
                          <span v-if="resData.use === true && resData.reservationStatus in visibleStatus" class="time underline pointer" @click="preview(resData, subItem.today)">
                            {{ resData.resTime }}
                          </span>
                          <span v-if="resData.use === true && resData.reservationStatus in visibleStatus" class="client-info underline pointer" @click="preview(resData, subItem.today)">
                            {{ resData.clientInfo }}
                          </span>
                          <span
                            v-if="resData.use === true && resData.reservationStatus in visibleStatus && resData.report === 'N'"
                            class="write-daily underline pointer"
                            @click="register(resData.idx)"
                          >
                            ▶ 일지작성
                          </span>
                        </div>
                        <br />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    </div>
    <preview
      @modifyPreview="modifyPreview"
      @modalCloseFromPreview="onPreviewCloseModal()"
      :onModal.sync="onModal"
      :idxCrmReservation="idxCrmReservation"
      :reservationStatus="reservationStatus"
      :clientType="clientType"
      :idxCrmClient="idxCrmClient"
      :idxCrmCompany="idxCrmCompany"
      :isModify.sync="isModify"
    ></preview>
    <register-modal
      ref="registerModal"
      @modalFromChild="onReginsterCloseModal()"
      :onRegisterModal.sync="onRegisterModal"
      :consStartDate.sync="consStartDate"
      :idxCrmStaff.sync="idxCrmStaff"
      :staffName.sync="staffName"
      :time.sync="time"
      :onIdxCrmCenter.sync="onIdxCrmCenter"
    ></register-modal>
    <modify-modal ref="modifyModal" @modalCloseFromModify="onModifyCloseModal()" :onModifyModal.sync="onModifyModal"></modify-modal>
  </div>
</template>
<script>
import "tui-calendar/dist/tui-calendar.css";
import { Calendar } from "@toast-ui/vue-calendar";
import Preview from "@/pages/counselor/reservationScheduleButton.vue";
import registerModal from "@/pages/origin/consult/consultScheduleRegister.vue";
import modifyModal from "@/pages/origin/consult/consultScheduleModify.vue";
import { RadioIcon, ChevronRightIcon } from "vue-feather-icons";
import store from "@/store/index";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: {
    calendar: Calendar,
    Preview,
    registerModal,
    modifyModal,
    RadioIcon,
    ChevronRightIcon,
    VueSlickCarousel,
  },

  data: () => ({
    consStartDate: "", // 기간검색-시작날짜
    consEndDate: "", // 기간검색-종료날짜
    tabType: "gangnam", // 탭 검색 (금일: today, 주간: week, 월간: month)

    // 상담사 일정 테이블 (api response용)
    counselorList: [],

    // 기본 시간 리스트
    timeLine: [
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
    ],

    // 각 시간별 일정 테이블 (로직 변환 최종 데이터)
    timeTable: [],

    boardList: [], //공지리스트

    today: "2020-01-01",
    todayYear: "2020",
    todayMonth: "01",
    todayDay: "01",
    todayDayOfWeek: "일",

    calendarTable: [],
    resData: [],
    loading: true,

    onRegisterModal: false,
    onModifyModal: false,
    onModal: false, // 주소 모달 on/off 여부

    staffName: "",
    idxCrmReservation: -1,
    reservationStatus: "",
    clientType: "",
    time: "",
    idxCrmClient: -1,
    idxCrmCompany: -1,
    idxCrmCenter: -1,
    onIdxCrmCenter: -1,
    idxCrmStaff: -1,
    isModify: false, //수정여부
    visibleStatus: { 예약: "", 입실: "", NS: "" }, //'당일변경','당일취소' 는 보여주지 않음
    //슬라이드 옵션
    slideOptions: {
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2500,
      autoplaySpeed: 5000,
      cssEase: "ease",
    },
  }),

  mounted() {
    this.getNotices();
    this.today = this.formatDate();
    this.todayYear = this.today.split("-")[0];
    this.todayMonth = this.today.split("-")[1];
    this.todayDay = this.today.split("-")[2];
    this.todayDayOfWeek = this.getDayOfWeek(new Date(this.today).getDay());

    this.consStartDate = this.todayYear + "-" + this.todayMonth;
    this.consEndDate = this.todayYear + "-" + this.todayMonth;
    this.getReservationList();
    //this.onMappingCalendar()
    // 상담일정 프론트 행에서 행 + 열로 변경 로직
    // 타임별로 object 추가
    for (var time of this.timeLine) {
      var tempData = {
        time: time,
        timeTable: [],
      };

      // 상담사 별로 각 시간별 timetable에 있는지 확인
      // 있으면 각 데이터와 use키에 true
      // 없으면 use키에 false
      // 시간표에 색상은 use로 판별함
      for (var counselor of this.counselorList) {
        var check = false;
        for (var eachTable of counselor.timeTable) {
          if (eachTable.time === time) {
            eachTable["use"] = true;
            tempData.timeTable.push(eachTable);
            check = true;
            break;
          } else {
            continue;
          }
        }

        if (check) {
          continue;
        } else {
          tempData.timeTable.push({
            use: false,
          });
        }
      }

      this.timeTable.push(tempData);
    }
  },

  methods: {
    /**
     * @description 공지사항 조회
     */
    getNotices() {
      var param = {
        page: this.page,
        pagesize: this.pagesize,
      };
      this.loading = true;
      //공지조회 api 호출
      this.axios
        .get(`/api/v1/board/One`, { params: param })
        .then((res) => {
          if (res.data.err === 0) {
            for (let index = 0; index < res.data.boardList.length; index++) {
              this.boardList.push({
                idx: res.data.boardList[index].idx,
                title: res.data.boardList[index].board_title,
                active: true,
                new: true,
              });
            }
            if (res.data.boardListCount) {
              this.listLength = res.data.boardListCount;
            }
            this.maxPagingCount = Math.ceil(this.listLength / this.pagesize);
            this.maxPagePart = Math.ceil(this.maxPagingCount / 10);
            this.pagePart = Math.ceil(this.page / 10);
          } else {
            this.boardList = [];
            // alert(res.data.err_msg);
          }
        })
        .catch((err) => {
          alert(`err : ${err}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    moveToNoticeDetail(idx) {
      this.$router.push(`/counselor/notice_detail/${idx}`);
    },
    moveToNotice() {
      this.$router.push(`/counselor/notice`);
    },
    // 상담일지 목록
    getReservationList() {
      let startDate = this.consStartDate + "-" + "01";
      let endDate = this.consEndDate + "-" + "31";
      let params = {
        consStartDate: new Date(startDate),
        consEndDate: new Date(endDate),
        pageSize: 1000,
        pageNum: 1,
        orderType: "reservMonth",
        idxCrmCenter: store.state.userInfo.idxCrmCenter,
        idxCrmStaff: store.state.userInfo.idx,
      };

      this.loading = true;
      this.axios
        .get("/api/v1/cons/list", {
          params: params,
        })
        .then((res) => {
          if (res.data.err === 0) {
            this.counselorList = res.data.resList;
          } else {
            //alert('검색 결과가 없습니다.')
            this.counselorList = [];
          }
          this.onMappingCalendar(this.counselorList);
        })
        .catch((err) => {
          alert(err);
          console.error(err);
          if (err.response.data.path === "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 이전 및 다음달로 변경시 달력 구조 변경 함수
    onMappingCalendar(resList) {
      this.calendarTable = [];
      const firstDay = new Date(this.todayYear, this.todayMonth, 1).getDate();
      const lastDay = new Date(this.todayYear, this.todayMonth, 0).getDate();
      for (var i = 1; i <= 32; i++) {
        if (i >= firstDay && i <= lastDay) {
        }
        // 그 달에 없는 일은 제외한다. (ex 9월은 30일까지 있어서 31은 제외해줘야함)
        if (isNaN(new Date(this.todayYear + "-" + this.todayMonth + "-" + i).getDay())) {
          // 다음달 요일을 추가한다.
          if (new Date(this.todayYear + "-" + this.todayMonth + "-" + (i - 1)).getDay() !== 6) {
            for (var j = new Date(this.todayYear + "-" + this.todayMonth + "-" + (i - 1)).getDay(); j < 6; j++) {
              this.calendarTable[this.calendarTable.length - 1].push({
                day: "", // 일
                week: this.calendarTable.length - 1, // 그 달의 몇번째 주인지
              });
            }
          }
          break;
        }

        // 달력의 첫 1일은 항상 row를 추가한다.
        if (i === 1) {
          this.calendarTable.push([]);
        } else if (i !== 1 && new Date(this.todayYear + "-" + this.todayMonth + "-" + i).getDay() === 0) {
          this.calendarTable.push([]);
        }

        // 이전 달 요일을 추가한다.
        if (i === 1 && new Date(this.todayYear + "-" + this.todayMonth + "-" + i).getDay() !== 0) {
          for (var j = 1; j <= new Date(this.todayYear + "-" + this.todayMonth + "-" + i).getDay(); j++) {
            this.calendarTable[this.calendarTable.length - 1].push({
              day: "", // 일
              week: this.calendarTable.length - 1, // 그 달의 몇번째 주인지
            });
          }
        }

        let first = "";
        let idx = -1;
        let idxCrmClient = -1;
        let report = "N";
        let resTime = "";
        let clientInfo = "";
        let typeName = "";
        this.resData = [];

        if (resList !== null) {
          for (var j = 0; j < resList.length; j++) {
            if (this.formatDate(new Date(this.todayYear + "-" + this.todayMonth + "-" + i)) === resList[j].consDate.slice(0, 10)) {
              if (resList[j].consNum === 1) {
                first = "첫상담";
              } else {
                first = "";
              }

              if (resList[j].reportYn === "Y") {
                report = "Y";
              } else {
                report = "N";
              }
              if (resList[j].consTime.length >= 84) {
                resList[j].consTime = resList[j].consTime.substring(0, 84) + "\n" + resList[j].consTime.substring(84);
              }
              resTime = resList[j].consTime;
              typeName = resList[j].typeName ? "(" + resList[j].typeName + ")" : "";
              clientInfo = resList[j].name + typeName + first;
              idx = resList[j].idx;
              idxCrmClient = resList[j].idxCrmClient;
              this.resData[j] = {
                first: first,
                idx: idx,
                report: report,
                use: true,
                resTime: resTime,
                clientInfo: clientInfo,
                idxCrmClient: idxCrmClient,

                time: resTime,
                name: resList[j].name,
                type: resList[j].typeName,
                consNum: resList[j].consNum,
                idxCrmReservation: resList[j].idx,
                idxCrmStaff: resList[j].idxCrmStaff,
                reservationStatus: resList[j].reservationStatus,
                clientType: resList[j].clientType,
                idxCrmCenter: resList[j].idxCrmCenter,
                idxCrmCompany: resList[j].idxCrmCompany,
                companyName: resList[j].companyName,
                contactType: resList[j].contactType,
                workTime: true,
                isModify: Number(store.state.userInfo.idxCrmCenter) === Number(resList[j].idxCrmCenter) ? true : false,
              };
            } else {
              this.resData[j] = {
                use: false,
              };
            }
          }
        }

        const day = i >= firstDay && i <= lastDay ? i : "";

        this.calendarTable[this.calendarTable.length - 1].push({
          today: this.formatDate(new Date(this.todayYear + "-" + this.todayMonth + "-" + i)),
          day: day, // 일
          resData: this.resData,
          dayOfWeek: this.getDayOfWeek(new Date(this.todayYear + "-" + this.todayMonth + "-" + i).getDay()), // 요일
          week: this.calendarTable.length - 1, // 그 달의 몇번째 주인지
        });
      }
    },

    // 미리보기 수정 모달 닫기
    onModifyCloseModal() {
      this.onModifyModal = false;
      this.getReservationList();
    },

    // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
    formatDate(date) {
      if (date) {
        var d = date,
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
      } else {
        var d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
      }
    },

    // 요일 가져오기
    getDayOfWeek(index) {
      switch (index) {
        case 0:
          return "일";
        case 1:
          return "월";
        case 2:
          return "화";
        case 3:
          return "수";
        case 4:
          return "목";
        case 5:
          return "금";
        case 6:
          return "토";
      }
    },

    // 다음 날로 옮기기
    nextDay() {
      // 다음 날이 다음달인 경우 달력 테이블 변경
      var date = new Date(this.today);

      //date.setDate(date.getDate() + 1);
      date.setDate(1);
      date.setMonth(date.getMonth() + 1);

      this.today = this.formatDate(date);
      this.todayYear = this.today.split("-")[0];
      this.todayMonth = this.today.split("-")[1];
      this.todayDay = this.today.split("-")[2];
      this.todayDayOfWeek = this.getDayOfWeek(new Date(this.today).getDay());

      //if (prevDate.getMonth() !== date.getMonth()) {
      //this.onMappingCalendar()
      //}
      this.consStartDate = this.todayYear + "-" + this.todayMonth;
      this.consEndDate = this.todayYear + "-" + this.todayMonth;

      this.getReservationList();
    },

    // 이전 날로 옮기기
    prevDay() {
      var date = new Date(this.today);
      var prevDate = new Date(this.today);

      //date.setDate(date.getDate() - 1);
      date.setMonth(date.getMonth() - 1);
      this.today = this.formatDate(date);
      this.todayYear = this.today.split("-")[0];
      this.todayMonth = this.today.split("-")[1];
      this.todayDay = this.today.split("-")[2];
      this.todayDayOfWeek = this.getDayOfWeek(new Date(this.today).getDay());

      //if (prevDate.getMonth() !== date.getMonth()) {
      //this.onMappingCalendar()
      //}
      this.consStartDate = this.todayYear + "-" + this.todayMonth;
      this.consEndDate = this.todayYear + "-" + this.todayMonth;
      this.getReservationList();
    },

    detail(idx) {
      this.$router.push(`/counselor/counselor_customer_manage_chart?idx=${idx}`);
    },

    // 상담일지 등록페이지로 이동
    register(idxCrmReservation) {
      this.$router.push(`/counselor/journal_register?idxCrmReservation=${idxCrmReservation}`);
    },

    // 예약 (모달 on)
    preview(subItem, today) {
      //sessionStorage.setItem("consStartDate", this.formatDate(this.consStartDate));
      if (subItem.idxCrmReservation !== undefined) {
        //기존 상담 예약건을 위한 경우.
        this.idxCrmReservation = subItem.idxCrmReservation;
        this.reservationStatus = subItem.reservationStatus;
        this.idxCrmClient = subItem.idxCrmClient;
        this.idxCrmCompany = subItem.idxCrmCompany;
        this.clientType = subItem.clientType;
        this.onModal = !this.onModal;
        this.isModify = Number(store.state.userInfo.idxCrmCenter) === Number(subItem.idxCrmCenter) ? true : false;
      } else {
        //신규 상담 예약 등록을 위한 경우.
        this.onIdxCrmCenter = store.state.userInfo.idxCrmCenter;
        this.onRegisterModal = !this.onRegisterModal;
        this.idxCrmStaff = Number(store.state.userInfo.idx);
        this.time = subItem.time;
        this.$refs.registerModal.radio();
        this.$refs.registerModal.getStaffSchedule2(this.idxCrmStaff, "", today);
        this.$refs.registerModal.getClientList(this.idxCrmStaff);
        this.$refs.registerModal.getPersonList();
      }
    },

    modifyPreview() {
      this.onModal = !this.onModal;
      this.onModifyModal = !this.onModifyModal;
      this.idxCrmReservation = this.idxCrmReservation;
      this.$refs.modifyModal.getCustomerData(this.idxCrmClient);
      this.$refs.modifyModal.getReservation(this.idxCrmReservation);
    },

    /**
     * @description 미리보기 등록 모달 닫기
     */
    onPreviewCloseModal() {
      this.onModal = false;
      this.getReservationList();
    },

    onReginsterCloseModal() {
      this.onRegisterModal = false;
      this.getReservationList();
    },
  },
};
</script>

<style lang="scss">
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
.con_top {
  margin-top: 15px;
}
.slider {
  margin-bottom: 20px;
}

.borad-cast {
  display: flex;
  justify-content: space-between;
}

.slick-list {
  width: 1150px;
}

.event-board {
  display: inline-block;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.new-button {
  width: 40px;
  height: 25px;
  background-color: #ff5f00;
  border-radius: 18px;
  border: none;
  color: #fff;
  font-family: "Noto Sans KR";
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  cursor: pointer;
}

.notice-title {
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: -0.8px;
  text-align: left;
  color: #666;
}
.boardLine {
  width: 99.8vw;
  margin-bottom: 36px;
  margin-left: calc(-49.9vw + 50%);
  border-bottom: solid 1px #e6e6e6;
  background-color: #e6e6e6;
}
</style>
